import React from 'react';
import Col from '../../ui/Grid/Col';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Icon from '../../ui/Icon';
import * as styles from './styles.module.scss';

type ContentIconTeaserItem = {
  text: string;
};

type ContentIconTeaserProps = {
  items: ContentIconTeaserItem[];
  btnText: string;
  btnTarget: string;
};

const ContentIconTeaser = ({ items, btnText, btnTarget }: ContentIconTeaserProps) => (
  <Container additionalClass={styles.iconTeaserWrap}>
    <Row additionalClass={styles.iconTeaserContainer}>
      {items.map((item, index) => (
        <Col additionalClass={styles.iconTeaserItem} key={index}>
          <Icon name="check" additionalClass={styles.check} />
          <p>{item.text}</p>
        </Col>
      ))}
    </Row>
    <a href={btnTarget} target="_blank" className={styles.btn}>
      {btnText}
    </a>
  </Container>
);

export default ContentIconTeaser;
